import { Link } from "react-router-dom";
import "./footer.css";

export const Footer = (props) => {
  return (
    <div className="debug footer">
      <div className="navi">
        <div className="item">
          <Link to="service.doc.html">服务条款</Link>
        </div>

        <div className="item">
          <Link to="privacy.doc.html">隐私声明</Link>
        </div>

        <div className="item">
          <Link to="community.doc.html">社区规范</Link>
        </div>

        <div className="item">
          <Link to="rule.doc.html">发帖准则</Link>
        </div>
      </div>
      <div class="footer2">
        <div class="footerBox">
          <p>
            COPYRIGHT © 2022Enmoli Inc. ALL RIGHTS RESERVED
            <a
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010302003008"
              target="_blank"
            >
              <img src="./img/footer.png" alt="" width="25" />
              公安备案：45010302003008
            </a>
            不良信息举报中心 网络110报警服务
          </p>
          <p>
            网络文化经营许可证：桂网文[2021]0944-047号   
            <a target="_blank" href="https://beian.miit.gov.cn/">
              桂ICP备2022009262号  
            </a>
            广西联酷网络科技有限公司
          </p>
          <p>
            地址：广西壮族自治区南宁市良庆区中国（广西）自由贸易试验区南宁片区平乐大道15号五象绿地中心2号楼14层1401号房146号
          </p>
        </div>
      </div>
    </div>
  );
};
