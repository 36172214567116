import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { Loading, Toast } from "react-vant";
import CardItem from "../../components/Cards/CardItem";
import { Suggestion } from "../../components/Suggestion";
import * as service from '../../service';

import "./circle.css";
const CircleRaw = (props)=>{
    const navigate = useNavigate();
    const params = useParams();
    const [circle,setCircle]  = useState(null);
    const [loading,setLoading] = useState(true);
    const [feeds, setFeeds] = useState([]);
    const reload =()=>{
        setLoading(true);
        service.loadCircle(params.id).then(ret=>{
            console.log('[DEbug]circle:',ret);
            setCircle(ret.data);
            
            setFeeds(ret.data.feeds);
            
        }).finally(()=>{
            setTimeout(() => {
                setLoading(false);    
            }, 500);
        })
    }
    useEffect(()=>{
        reload();
    },[params.id]);
    
    if(loading){
        return (
            <div className="fx-box fx-fd-r">
                <div className="fx-box fx-ai-c fx-jc-c circle">
                    <Loading></Loading>
                </div>
                <div>
                    <Suggestion/>
                </div>
                
            </div>
        )
    }
    if(circle?.is_joined){
        return (
            <div className="fx-box fx-fd-r">
                <div className="fx-box circle">
                    {
                        feeds.map((feed,idx)=>{
                            return <CardItem item = {feed} key={'fi_'+idx}/>
                        })
                    }
                </div>
                <div>
                    <Suggestion/>
                </div>
                
            </div>
        )
    }
    return (
        <div className="fx-box fx-fd-r">
            <div className="circle">
                <div className="fx-box fx-ai-c circle-head">
                    <img src={circle?.avatar} className="circle-icon"></img>
                    <div className="fx-box fx-fd-c layout-ml20">
                        <div>{circle?.title}</div>
                        <div className="circle-info-sub">{circle?.total_feeds}帖子</div>
                    </div>
                </div>
                <div className="circle-owner">
                    <div className="fx-box fx-ai-c">
                        <img src={circle?.member?.avatar} className="circle-owenr-head"></img>
                        <div>{circle?.member?.nickname}</div>
                    </div>
                </div>
                <div className="circle-section">
                    <div className="title">
                        简介
                    </div>
                    <div>
                    {circle?.bio}
                    </div>
                </div>
                {
                    circle?.price?<>
                        <div className="circle-section">
                    <div className="title">付费须知</div>
                    <div>
                    <p>1.付费成功后即可查看该圈内容，并参与讨论；</p>
                    <p>
2.本圈由圈主自行运营，付费前请确认风险，本平台不提供相关保证，若发现违规圈子，请勿加入</p><p>
3.解锁后会有单独付费内容</p>
                    </div>
                </div>
                <div className="circle-section">
                    <div className="title">门票价格：<span className="price">{circle?.price}金币</span>
                    </div>
                </div>

                    </>:null
                }
                
                <div className="fx-box fx-jc-c">
                    <div className="join-btn" onClick={()=>{
                        service.joinCircle(params.id).then(ret=>{
                            if(ret.code!=0){
                                Toast.fail(ret.msg);
                                setTimeout(()=>{
                                    navigate('/wallet');
                                },500)
                            }
                            else Toast.success('成功加入圈子');
                        }).catch(err=>{
                            console.error('[DEBUG]APP ERROR:',err)
                            props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                        });
                    }}>
                        立即加入
                    </div>
                </div>
            </div>
            <div>
                <Suggestion/>
            </div>
            
        </div>
    )
}

export default connect()(CircleRaw);

