import React from 'react';
import { useNavigate } from 'react-router-dom';
import './searchs.css';
const SearchItem=(props)=>{
  const {circle} = props;
  const navigate = useNavigate();

  console.log('circle:',circle);
      return (
        <div className='search-item'>
             <div>
                <img src={circle?.avatar}/>
             </div>

             <div className='search-item-r'>
               <div>{circle?.name}</div>
               <div className='search-item-desc fx-box fx-ai-c fx-jc-sb'>
                  <div className='search-item-dl'>{circle?.bio}</div>
                  <div className='search-item-btn' onClick={()=>{
                    navigate('/circle/'+circle.id)
                  }}>加入</div>
               </div>
               <div className='search-item-num'>
               {circle?.total_feeds}帖子
               </div>
             </div>
        </div>
      );
}
export default SearchItem;