import React, { useState } from 'react';
import './cards.css';

import DropdownPopup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import Reward from '../Reward';
import * as service from '../../service';


const Dropdown =(props)=>{
   const navigate = useNavigate();
   return (
       <DropdownPopup trigger={open => (    
         <img className='drop-img' src='../../images/drop-icon.png'/>
               )}
           position="bottom center"
           closeOnDocumentClick >
               <div className="leave-menu">
                   <div className="item" onClick={()=>{
                       props.onLeave&&props.onLeave();
                   }}>退出圈子</div>
                   
               </div>
        
       </DropdownPopup>
   )
} 


const Introduction=(props)=>{
   const [showReward,setShowReward] = useState(false);
   const {circle} = props;
      return (<div className='Introduction basic-theme-font fx-box fx-jc-sb'>
           
             <div className='Introduction-left fx-box'>
                  <div>
                     <img src={circle?.avatar} className='Introduction-left-img'/>
                  </div>
                  <div>
                     <div className='Introduction-name font-size16 font-color3'>{circle?.title}</div>
                     <div className='Introduction-num font-size13 font-color4'>{circle?.total_feeds}帖子</div>
                     <div className='Introduction-tags fx-box'>
                          <div className='fx-box fx-ai-c fx-jc-sb' onClick={()=>{
                           setShowReward(true);
                          }}>
                             <img src='../../images/dashang.png'/>
                             <span className='font-color5 font-size13'>打赏</span>
                          </div>

                          <div className='fx-box fx-ai-c fx-jc-sb'>
                             <img src='../../images/jiaweixin.png'/>
                             <span className='font-color5 font-size13'>加微信</span>
                          </div>

                          {/* <div className='fx-box fx-ai-c fx-jc-sb'>
                             <img src='../../images/search-icon.png'/>
                             <span className='font-color5 font-size13'>tag1</span>
                          </div> */}
                     </div>
                  </div>
             </div>

             <div className='Introduction-right'>
                   {!props.isother?<Dropdown onLeave={()=>{
                     props.onLeave&&props.onLeave();
                   }}/>:<img className='drop-img' src='../../images/drop-icon1.png'/>}
                   
             </div>
             <Reward visible = {showReward} 
             onPay = {(money)=>{
               service.reward(money,circle?.id).then(ret=>{
                  console.log('ret',ret);
                  window.open(ret.data?.ticket, '_blank')
                  setShowReward(false);
               })
             }}
             onClose={()=>{
               setShowReward(false);
             }}/>
      
      </div>)
}

export default Introduction;