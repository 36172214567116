import React,{ useEffect } from 'react';
import { HashRouter as Router,Routes, Route} from 'react-router-dom'
import {Layout} from '../pages/Layout';
import {Home}  from '../pages/Home';
import { connect } from 'react-redux';
import * as service from '../service';
import AccountHistory from '../pages/AccountHistory';
import Circle from '../pages/Circle';
import FeedBack from '../pages/FeedBack';
import Search from '../pages/Search';
import Charge from '../pages/Charge';

import UpdateInfo from '../pages/UpdateInfo';
import Moments from '../pages/Moments';
import MomentDetail from '../pages/MomentDetail';

import { ServiceDoc } from '../pages/Policy/service';
import { PrivacyDoc } from '../pages/Policy/privacy';
import { RuleDoc } from '../pages/Policy/rule';
import { CommunityDoc } from '../pages/Policy/community';
import { Moment } from '../pages/Moment';




const Routers= (props)=>{
    /* eslint-disable */
    useEffect(()=>{
        if(props){
            if(props.loginInfo){
                console.log(props.loginInfo);
                service.getProfile().then(ret=>{
                    props.dispatch({type:'PROFILE',payload:ret.data});
                })
                // service.usersig().then((ret)=>{
                //     return ret.data;
                // }).catch(err=>{
                //     props.dispatch({type:'LOGIN_FAIL',payload:err});
                // })
            }
        }
        
    },[props.loginInfo,props.tim])
    /* eslint-enable */
    return (
        <Router>                
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path="" element={<Home/>}/>
                    <Route path="accounthistory" element={<AccountHistory/>}/>
                    <Route path='circle/:id' element={<Circle/>}/>
                    <Route path="feedback"  element={<FeedBack/>}/>
                    <Route path="search/:criteria" element={<Search/>}/>
                    <Route path="wallet" element={<Charge/>}/>
                    <Route path="updateinfo" element={<UpdateInfo/>}/>
                    <Route path="moments" element={<Moments/>}/>
                    <Route path="moment/:id" element={<Moment/>}/>
                    <Route path="detail/:id" element={<MomentDetail/>}/>
                    <Route path="service.doc.html" element={<ServiceDoc/>}/>
                    <Route path="privacy.doc.html" element={<PrivacyDoc/>}/>
                    <Route path="rule.doc.html" element={<RuleDoc/>}/>
                    <Route path="community.doc.html" element={<CommunityDoc/>}/>
                    
                </Route>
            </Routes>
        </Router>
    )
}


export default connect(
    state=>{
        return(
            {loginInfo:state.loginInfo,
                tim:state.tim
            }
        )
    },
    dispatch=>({dispatch})
)(Routers)