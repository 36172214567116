import React, { useEffect, useState } from 'react';
import './detail.css';
import CardItem from '../../components/Cards/CardItem';
import { useParams } from 'react-router-dom';
import * as service from '../../service';
import { connect } from 'react-redux';

const MomentDetail=(props)=>{
   const params = useParams();
   const [item,setItem] = useState(null);
   const [comments,setComments] = useState([]);
   const [comment,setComment] = useState('');
   const reload = ()=>{
      service.loadFeed(params.id).then(ret=>{
         setItem(ret.data);
         return service.loadComments(params.id)
      }).then(ret=>{
         console.log('[DEBUG]comments:',ret);
         setComments(ret.data.data);
      });
   }
   useEffect(()=>{
     reload();
   },[params.id]);
      // let [item,setitem]=useState(
      //   {
      //       title:'',
      //       time:'',
      //       albums:[

      //       ],
      //       isfree:0,//0表示免费 1表示付费
      //       righticon:'menu'
      //    }
      // );
      return (
        <div className='details basic-theme-font'>
              <div>
                <div className='moment-board'>
                    <CardItem item={item}/>
                </div>

                <div className='moment-commands'>
                   <div className='moment-commands-keyin fx-box'>
                        <div>
                           <img src={props.profile?.avatar}/>
                           
                        </div>
                        <div>
                           <textarea placeholder='写下你的评论' value={comment} onChange={(e)=>{
                              setComment(e.target.value);
                           }}></textarea>
                        </div>
                   </div>
                   <div className='commands-submit  fx-box fx-jc-e fx-ai-c'>
                      <div className='commands-submit-btn fontw6' onClick={()=>{
                        console.log('[DEBUG]comment item:',item);
                        
                        if(!params?.id)return;

                        service.comment(params?.id,comment).then(ret=>{
                           console.log(ret);
                           setComment('');
                           reload();
                        })
                      }}>评论</div>
                   </div>

                   <div className='commands-list'>
                      <div className='commands-title font-size16 fontw6'>全部评论</div>

                      <div className='commands-box'>
                           {
                              comments?.map((c,idx)=>{
                                 return (
                                    <div className='commands-item'>
                                       <div className='fx-box fx-ai-c'>
                                          <img className='commands-img' src={c?.member?.avatar??'/images/avatar.png'}/>
                                          <span className='font-size16 font-color3'>{c?.member?.nickname}</span>
                                       </div>
                                       <div className='commands-content fx-box fx-jc-sb'>
                                          <span className='font-size16 font-color3'>{c?.content}</span>
                                          <div className='font-color1' onClick={()=>{
                                             setComment('回复 '+c?.member?.nickname+': ');
                                          }}>回复</div>
                                       </div>
                                    </div>
                                 )
                              })
                           }                           
                      </div>
                   </div>



              </div>

              </div> 
        </div>
      );
}

export default connect(state=>{
   return {profile:state.profile}
})(MomentDetail);