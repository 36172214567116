import "./rule.css";

export const CommunityDoc = (props)=>{
    return (
        <div className="rule-container">
<h1>价值观</h1>

<h3>圈子倡导用户发布符合当代社会主流价值观的内容。</h3>

<p>1. 遵守宪法和法律法规；</p>

<p>2. 践行社会主义核心价值观；</p>

<p>3. 弘扬爱国主义、集体主义和社会主义；</p>

<p>4. 传播正确的历史观、民族观、国家观和文化观， 弘扬中华民族优秀传统文化， 弘扬社会公德、职业道德、家庭和个人美德，尊重公序良俗， 弘扬科学精神、普及科学知识， 提倡积极健康向上的时代风尚和生活方式。</p>

<h3>法律法规</h3>

<p>圈子对于社区内违反法律法规、危害国家及社会安全的行为，将采用最严格的管理办法，予以杜绝。</p>

<h3>1. 遵守基本准则</h3>

<p>圈子平台禁止发布和传播包含下列信息的内容</p>

<p>（a）违反宪法所确定的基本原则的；</p>

<p>（b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>

<p>（c）损害国家荣誉和利益的，如损害国旗国徽形象的；</p>

<p>（d）煽动民族仇恨、民族歧视，破坏民族团结的；</p>

<p>（e）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>

<p>（f）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

<p>（g）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>

<p>（h）侮辱或者诽谤他人，侵害他人合法权益的；</p>

<p>（i）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>

<p>（j）侮辱滥用英烈形象，否定歪曲英烈事迹，美化或粉饰侵略者和侵略战争行为的；</p>

<p>（k）攻击诋毁党和国家领导人，捏造领导人负面信息，滥用领导人形象的；</p>

<p>（l）违背公序良俗的内容，如低俗婚闹等；</p>

<p>（m）含有法律、行政法规禁止的其他内容的。</p>

<h3>2. 保护未成年人合法权益</h3>

<p>为更好地承担保护未成年人合法权益的社会责任，圈子平台对不利于未成年人身心健康的内容加以严肃处理，包括但不限于以下内容：</p>

<p>（a）涉及未成年人色情低俗的，如展示未成年人婚育、性侵害等；</p>

<p>（b）涉及未成年人暴力欺凌的，如施暴犯罪、残害体罚、校园欺凌等；</p>

<p>（c）涉及披露未成年人隐私的，如展示未成年人性器官，公开个人隐私等；</p>

<p>（d）涉及未成年人不良行为的，如宗教传授、饮酒吸烟、邪典动画等；</p>

<p>（e）其他危害未成年人安全和健康的相关内容。</p>

<h3>3. 抵制一切违法信息</h3>

<p>（a）禁止传播违法内容；</p>

<p>    （i）涉及违禁物品，包括但不限于：毒品、违禁药物、违法工具等，如大麻、鸦片、处方药物、投资理财产品、假币、枪支、烟草、爆炸物、翻墙软件、外挂程序等其他内容；</p>

<p>    （ii）涉及违禁活动，包括但不限于：有关传销、胎儿性别鉴定、售卖假货等其他内容。</p>

<p>（b）禁止发布欺诈或赌博信息</p>

<p>    （i）发布欺诈信息，如假冒官方客服、正规网站，以短信、站内私信或链接等形式提示虚假中奖信息，设计“杀猪盘”类欺诈行为等；</p>

<p>    （ii）提供赌博交易平台信息或赌博性质类服务，如赌博平台链接、币商、中介、游戏币回收等；</p>

<p>    （iii）涉及赌博技巧、赌博器具等赌博内容，如老虎机、透视扑克等。</p>

<h3>4. 抵制一切色情低俗内容</h3>

<p>禁止发布、传播的信息包括但不限于：</p>

<p>（a）直接暴露和描写人体性部位的内容；</p>

<p>（b）表现或隐晦表现性行为、具有挑逗性或者侮辱性的内容；</p>

<p>（c）以带有性暗示、性挑逗的语言描述性行为、性过程、性方式的内容；</p>

<p>（d）全身或者隐私部位未着衣物，仅用肢体掩盖隐私部位的内容；</p>

<p>（e）带有侵犯个人隐私性质的走光、偷拍、漏点等内容；</p>

<p>（f）以庸俗和挑逗性标题吸引点击的内容；</p>

<p>（g）相关部门禁止传播的色情和有伤社会风化的文字、音视频内容，包括一些电影的删节片段；</p>

<p>（h）传播一夜情、换妻、性虐待等的有害信息；</p>

<p>（i）情色动漫、小说；</p>

<p>（j）宣扬暴力、恶意谩骂、侮辱他人等的内容；</p>

<p>（k）非法的性药品广告和性病治疗广告等相关内容；</p>

<p>（l）恶意传播侵害他人隐私的内容；</p>

<p>（m）推介淫秽色情网站和网上低俗信息的链接、图片、文字等内容。</p>

<h1>社区处罚管理规范</h1>

<p>违规内容处理方式包括：</p>

<h3>1. 内容处理</h3>

<p>（a）屏蔽违规内容；</p>

<p>（b）删除部分违规内容；</p>

<p>（c）替换违规内容；</p>

<p>（d）删除整个违规内容。</p>

<h3>2. 账号处理</h3>

<p>（a）限制账号功能，如：作弊账号无法申请为创作者；</p>

<p>（b）限制展示范围；</p>

<p>（c）账号禁言；</p>

<p>（d）账号封禁；</p>

<p>（e）依法追究法律责任。</p>

        </div>
    )
}