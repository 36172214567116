import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { Popup } from "react-vant";
import * as service from '../../service';

import WithdrawDialog from "./withdraw";

import "./my.css";
const MyRaw = (props)=>{
    const [showIncome,setShowIncome]  = useState(false);
    const [showPay,setShowPay]  = useState(true);
    const [list,setList] = useState([]);
    const [showDialog,setShowDialog] = useState(false);

    useEffect(()=>{
        service.loadAccountHistory(showIncome).then(ret=>{
            console.warn(ret);
        })
    },[showIncome])
    return (
        <div className="fx-box fx-fd-c my">
            <div className="fx-box fx-fd-c">
                <div className="fx-box fx-ai-c top">
                    <img src="/images/my-icon.png" className="indicator"></img>
                    <div className="title">收支明细</div>
                </div>
                <div className="fx-box">
                    <div className="fx-box fx-ai-c value-block rightline">
                        <img className="avatar" src={props.profile?.avatar??"/images/avatar.png"}></img>
                        <div className="fx-box fx-fd-c">
                            <div className="name">{props.profile?.nickname}</div>
                            <div className="namevalue">{props.profile?.username}</div>
                        </div>

                    </div>
                    <div className="value-block rightline fx-jc-c">
                            <div>今日收益</div>
                            <div className="numvalue">{props.profile?.income_today}</div>
                    </div>
                    <div className="value-block fx-jc-c fx-fd-c">
                        <div className="fx-box fx-ai-c">
                            <div>余额</div>
                            <div className="numvalue">{props.profile?.balance}</div>
                        </div>
                        <div className={"withdraw "+(props.profile?.balance<1000?'disabled':'')} onClick={
                            ()=>{
                                setShowDialog(true);
                            }
                        }>
                            <div>提现</div>
                        </div>
                    </div>
                </div>
                <div className="fx-box account-tab">
                    <div className={"fx-box fx-fd-c tab "+(showIncome?"active":"")} onClick={()=>{
                        setShowIncome(true);
                        setShowPay(false);
                    }}>
                        <div>收入</div>
                        {showIncome?<div className="bline"></div>:null}
                    </div>
                    <div className={"fx-box fx-fd-c tab "+(showPay?"active":"")} onClick={()=>{
                        setShowIncome(false);
                        setShowPay(true);
                    }}>
                        <div>支出</div>
                        {showPay?<div className="bline"></div>:null}
                    </div>
                </div>
                <div className="account-data">
                    <div className="row">
                        <div>时间</div>
                        <div className="fx-1">名目</div>
                        <div>金额</div>
                        <div>状态</div>
                        <div>原因</div>
                    </div>
                    {/* <div className="data row">
                        <div>2023-03-30 14:33</div>
                        <div className="fx-1"> 门票收入</div>
                        <div>1000</div>
                        <div>已到账</div>
                        <div></div>
                    </div>
                    <div className="data row">
                        <div>2023-03-30 14:32</div>
                        <div className="fx-1"> 动态收益</div>
                        <div>990</div>
                        <div>已到账</div>
                        <div></div>
                    </div> */}
                    {
                        list?.map((e,idx)=>{
                            return (
                                <div className="data row">
                                <div>{e.date}</div>
                                <div className="fx-1">{e.category}</div>
                                <div>{e.amount}</div>
                                <div>{e.status}</div>
                                <div>{e.memo}</div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            <WithdrawDialog visible={showDialog} onClose={()=>{
                setShowDialog(false);
            }}/>
        </div>
    )
}

export default connect(state=>{
    return {profile:state.profile}
})(MyRaw);

